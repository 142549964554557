<template>
    <div class="evaluate-count-report">
        <div class="evaluate-count-report-content">
            <div class="evaluate-count-report-title">
                <div class="table-title">推荐评价明细：{{totalCount}}</div>
                <div class="evaluate-count-report-top">
                    <div class="date-wrap triangle-drop" :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                        <el-date-picker
                            v-model="dateRange"
                            type="daterange"
                            start-placeholder="开始日期"
                            range-separator="-"
                            end-placeholder="结束日期"
                            size="small"
                            :editable="false"
                            align="center"
                            format="yyyyMMdd"
                            @change="handleDate"
                            @focus="handleFocusDate"
                            @blur="handleBlurDate"
                            :clearable="false"
                            :picker-options="expireTimeOption"
                            popper-class="performance-data-range">
                        </el-date-picker>
                    </div>

                    <el-button
                        class="export"
                        type="primary"
                        :loading="exportLoading"
                        @click="handleExport"
                        >导出明细</el-button
                    >
                </div>
            </div>

            <div class="evaluate-count-report-table">
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    style="width: 100%"
                    tooltip-effect="light"
                >
                    <el-table-column
                        prop="evaluator"
                        align="left"
                        width="130"
                        :show-overflow-tooltip="true"
                        label="评价人"
                    >
                    </el-table-column>
                    <el-table-column
                        align="left"
                        width="130"
                        :show-overflow-tooltip="true"
                        label="评价时间"
                    >
                        <template slot-scope="{ row }">
                            <span>{{row.evaluateTime | dateFilter}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="evaluateScore"
                        align="left"
                        width="120"
                        :show-overflow-tooltip="true"
                        label="评价分数"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="evaluateContent"
                        align="left"
                        width="160"
                        :show-overflow-tooltip="true"
                        label="评价内容"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="offerCount"
                        align="left"
                        width="130"
                        :show-overflow-tooltip="true"
                        label="推荐时间"
                    >
                        <template slot-scope="{ row }">
                            <span>{{row.recommendationTime | dateFilter}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommendationId"
                        align="left"
                        width="340"
                        :show-overflow-tooltip="true"
                        label="推荐ID"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="customerName"
                        align="left"
                        width="130"
                        :show-overflow-tooltip="true"
                        label="推荐客户"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="jobName"
                        align="left"
                        width="160"
                        :show-overflow-tooltip="true"
                        label="推荐职位"
                    >
                        <template slot-scope="{ row }">
                            <span class="job-name-btn" @click="jumpViewJob(row)">{{row.jobName}}</span>
                            <!-- <el-button type="text" primary>查看</el-button> -->
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommendationStatusName"
                        align="left"
                        width="160"
                        :show-overflow-tooltip="true"
                        label="当前推荐状态"
                    >
                    </el-table-column>
                    <el-table-column
                        align="left"
                        width="120"
                        :show-overflow-tooltip="true"
                        label="操作"
                    >
                        <template slot-scope="{ row }">
                            <span class="view-btn" @click="jumpViewEvaluate(row)">查看</span>
                            <!-- <el-button type="text" primary>查看</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="work-table-footer">
                    <el-pagination
                        class="el-pagination-workTable"
                        layout="total, sizes, prev, pager, next, slot"
                        :current-page="page"
                        :page-sizes="[15, 20, 50]"
                        :page-size="pageSize"
                        :total="totalCount"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                        <span class="pagination-text">
                            <span
                                >前往<el-input
                                    v-model="pagerJump"
                                    @keyup.enter.native="handlePagerJump"
                                ></el-input
                                >页</span
                            >
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { performances_detail_new } from "#/js/config/api.json";
import { downloadFile } from "#/js/util/fileUtil.js";

const YESTODAY = moment().subtract(1, 'days').format("YYYY-MM-DD");
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");

export default {
    name: "PersonalEvaluateCountReport",
    components: {
    },
    data() {
        let _minTime = null,
            _maxTime = new Date().getTime();
        return {
            json: {},
            loading: false,
            dateFormat: "yyyy-MM",
            totalCount: 0,
            page: 1,
            pageSize: 15,
            pagerJump: 0,
            startDate: "",
            endDate: "",
            dateRange: [],
            userIds: [],
            tableData: [],
            isFocusDate: false,//用于控制日期的三角
            exportLoading: false,
            expireTimeOption: {
                onPick: time => {
                    // 如果选择了只选择了一个时间
                    if (!time.maxDate) {
                        let _nDays = this.key == 'trackingListDetails' ? 31: 365;
                        let timeRange = _nDays*24*60*60*1000, // *天
                            _dNow = new Date().getTime();

                        _minTime = time.minDate.getTime() - timeRange; // 最小时间
                        _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                        _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                    // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                    } else {
                        _minTime = _maxTime = null;
                    }
                },
                disabledDate: time => {
                    // onPick后触发
                    // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                    if(_minTime && _maxTime){
                        return time.getTime() < _minTime || time.getTime() > _maxTime;
                    }
                    if(!_minTime && _maxTime) {
                        return time.getTime() > _maxTime;
                    }
                },
                // disabledDate(date) {
                //     return date.getTime() > Date.now();
                // },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        const start = moment().weekday(0).format('YYYY-MM-DD');
                        const end = moment().weekday(6).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
                // ,{
                //     text: '今年',
                //     onClick(picker) {
                //         const start = moment().year() + '-01-01';
                //         const end = moment().format('YYYY-MM-DD');
                //         picker.$emit('pick', [start, end]);
                //     }
                // }
            },
        };
    },
    computed: {},
    created() {},
    filters: {
        dateFilter(val) {
            if (!val) {
                return '-';
            }
            return moment(val).format('YYYY-MM-DD')
        }
    },
    mounted() {
        if(sessionStorage.getItem('personalEvaluateCountReport')) {
        }else if(localStorage.getItem('perpormancesDetailData')) {
            let jsonStr = localStorage.getItem('perpormancesDetailData');
            sessionStorage.setItem('personalEvaluateCountReport', jsonStr);
        }
        this.json = JSON.parse(sessionStorage.getItem('personalEvaluateCountReport'));
        this.userIds = this.json.userIds;

        this.startDate = this.json.startDate || lastMonth;
        this.endDate = this.json.endDate || YESTODAY;
        this.dateRange = [this.startDate, this.endDate];

        this.getData();
        this.loading = true;
    },
    methods: {
        jumpViewJob(item) {
            window.open(`/#/Job/${item.jobId}`);
        },
        jumpViewEvaluate(item) {
            console.log('查看');
            if (item.recommendationTime) {
                let dateStr = moment(item.recommendationTime).format('YYYY-MM-DD HH:mm');
                window.open(`/#/Job/${item.jobId}/recommendation/${item.recommendationId}?recommendDate=${dateStr}`);
            } else {
                window.open(`/#/Job/${item.jobId}/recommendation/${item.recommendationId}`);
            }
        },
        handleDate(value) {
            let jsonStr = JSON.parse(sessionStorage.getItem('personalEvaluateCountReport'));
            this.startDate = (jsonStr.startDate = moment(value[0]).format('YYYY-MM-DD'));
            this.endDate = (jsonStr.endDate = moment(value[1]).format('YYYY-MM-DD'));
            sessionStorage.setItem('personalEvaluateCountReport', JSON.stringify(jsonStr));
            this.handleCurrentChange(1);
        },
        handleFocusDate() {
            this.isFocusDate = true;
        },
        handleBlurDate() {
            this.isFocusDate = false;
        },
        // 分页
        handleSizeChange(val) {
            this.page = 1;
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getData();
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount / this.pageSize);
            if (currentPager > 0 && currentPager <= currentPageTotal) {
                this.page = currentPager;
                this.getData();
            }
        },
        // 获取业务线列表数据
        getData() {
            this.loading = true;
            let data = {
                offset: (this.page - 1) * this.pageSize,
                pageSize: this.pageSize,
                startDate: this.startDate,
                endDate: this.endDate,
                isTeam: 0,
            };
            _request({
                method: "POST",
                url: performances_detail_new.evaluate_detail,
                baseURL: "LbdJavaApi",
                javaProject: "performance",
                data: data,
            }).then((res) => {
                // console.log(res);
                let list = res.data || [];

                this.totalCount = res.count;
                // this.tableData = res.data;
                this.tableData = list;
                this.loading = false;

                this.$nextTick(() => {
                    // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
                    // const _scrollTop =
                    //     document.body.scrollTop ||
                    //     document.documentElement.scrollTop;
                    const _scrollTop = document.querySelector(".evaluate-count-report").scrollTop;
                    if (_scrollTop > 200) {
                        // document.documentElement.scrollTop = 0;
                        // document.body.scrollTop = 0;
                        // console.log("scroll top");
                        document.querySelector(".evaluate-count-report").scrollTop = 0;
                    }
                });
            }).catch(err => {
                this.loading = false;
            });
        },

        // 导出
        handleExport() {
            this.exportLoading = true;
            let params = {
                // offset: (this.page - 1) * this.pageSize,
                // pageSize: this.pageSize,
                startDate: this.startDate,
                endDate: this.endDate,
                isTeam: 0,
            };
            _request({
                method: "POST",
                baseURL: "LbdJavaApi",
                javaProject: "performance",
                url: performances_detail_new.export_evaluate_detail,
                responseType: "blob",
                data: params,
            })
                .then((res) => {
                    downloadFile(res);
                })
                .catch((err) => {
                    shortTips(err.message || "系统异常");
                })
                .finally(() => {
                    this.exportLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scope>
.evaluate-count-report {
    // background-color: #f9f9f9;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    &-content {
        padding-bottom: 38px;
        background-color: #fff;
        border-radius: 8px;
        min-height: calc(100vh - 90px);
    }

    &-title {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        padding: 30px 30px 0;
        margin-bottom: 8px;

        .table-title {
            font-size: 28px;
            color: #666;
            line-height: 32px;
            > span {
                color: $primary;
            }
        }
    }

    &-top {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        position: relative;
        // width: 100%;
        text-align: center;

        .filter-type{
            width: 200px;
            height: 32px;
            margin-right: 20px;

            .el-input__inner {
                height: 32px;
            }

            .el-input__icon {
                height: 32px;
                line-height: 32px;
            }
        }

        .el-select.select-client {
            &:hover {
                .el-input__inner {
                    background: #38bc9d;
                    border-color: #38bc9d;
                    color: #fff;
                    &::placeholder {
                        color: #fff;
                    }
                }
            }
            input.el-input__inner {
                width: 118px;
                height: 32px;
                // border-radius: 16px;
                // &:hover {
                //     background-color: #38bc9c;
                // }
            }
            .el-icon-arrow-up:before {
                display: none;
            }
        }
        .export {
            // position: absolute;
            // right: 0;
            margin-left: 20px;
        }

        .date-wrap {
            display: inline-block;
            vertical-align: top;
            position: relative;
            cursor: pointer;
            // width: 190px;
            width: 260px;
            margin-right: 20px;
            &:hover{
                .el-date-editor--daterange.el-input__inner{
                    border-color:#38BC9D;
                    background: #38BC9D;
                    .el-range-input{
                        background: #38BC9D;
                        color: #fff;
                    }
                    .el-range-separator{
                        color: #fff;
                    }
                }
            }
            .el-range-editor.is-active{
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
            &.triangle-drop-up{
                &::after{
                    border-bottom: 12px solid #fff;
                }
            }
            .el-date-editor--daterange.el-input__inner{
                // width: 190px;
                width: 260px;
                // border-radius: 16px;
            }
            .el-date-editor .el-range__icon{
                // display: none;
            }

            .el-date-editor .el-range__close-icon{
                display: none;
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }
    }

    .view-btn {
        color: $primary;
        cursor: pointer;
    }

    .job-name-btn {
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }

    .work-table-footer {
        margin: 35px 35px 0 0;
        overflow: hidden;
    }

    .el-table {
        th, td {
            padding: 8px 0;
        }
        thead.has-gutter tr th {
            background-color: #F0F4F7;
            .cell {
                color: #666;
                font-weight: bold;
                padding: 0 20px;
            }
        }
        td .cell {
            padding: 0 20px;
        }
    }
}
</style>
<style lang="scss">
.evaluate-count-report {
    /deep/ .el-input__inner {
        &::placeholder {
            color: #606266;
        }
    }
}
</style>